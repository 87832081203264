"use client";

/**
 * This should only be rendered when everything else goes wrong - ideally our errors should be caught using ForgeErrorBoundary component where possible. The plain HTML in this component
 * should be kept up to date with the look and feel of the site.
 */
export default function GlobalError() {
    return (
        <html lang="en">
            <link rel="icon" href="/etc/designs/research/clientlibs/img/favicon/favicon.ico" sizes="any" />
            <body>
                <div className="bg-surface-secondary flex flex-col">
                    <div>
                        <div className="h-[120px]">
                            <div className="bg-white top-0 w-full z-10 md:fixed" data-testid="HeaderTemplate">
                                <div className="my-0 mx-auto min-h-24 pl-2 pt-2 pb-2 md:w-[1190px] md:block md:inset-x-0">
                                    <div className="relative left-0" data-testid="Logo">
                                        <a href="https://publishing.gs.com">
                                            <img
                                                src="https://publishing.gs.com/content/dam/research/logos/generic/GS-logo.png/jcr:content/renditions/cq5dam.thumbnail.319.319.png"
                                                className="inline-block max-h-14"
                                                alt="Goldman Sachs Research"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="min-h-10">
                        <div>
                            <div
                                className="mx-auto my-5 md:w-[750px] lg:w-[990px] lg:w-[1190px]"
                                data-testid="ErrorSection">
                                <div className="bg-white border rounded-sm">
                                    <div className="p-4">
                                        <div>
                                            <h1 className="font-sans font-medium text-[40px]/[52px] text-text-primary undefined">
                                                Internal Server Error
                                            </h1>
                                            <p>&nbsp;</p>
                                            <p>
                                                <span className="font-sans font-normal text-base text-text-primary max-w-none">
                                                    Please let us know the page you were trying to access by contacting
                                                    us at&nbsp;
                                                    <a href="mailto:gsresearchhelp@gs.com">gsresearchhelp@gs.com</a>
                                                    .<br />
                                                </span>
                                            </p>
                                            <p>
                                                <span className="font-sans font-normal text-base text-text-primary max-w-none">
                                                    Return to the Goldman Sachs Research homepage at&nbsp;
                                                    <a href="https://research.gs.com/">research.gs.com</a>
                                                    .<br />
                                                </span>
                                            </p>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white"></div>
                </div>
            </body>
        </html>
    );
}
